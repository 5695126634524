<template>
  <v-container>
    <v-card v-if="versions" class="mx-auto my-12" max-width="90%" flat>
      <v-alert
          border="top"
          colored-border
          type="info"
          color="orange"
          elevation="2"
      >
        このサイトの利用にはあらかじめユーザ登録が完了している必要があります。
      </v-alert>
    </v-card>
    <v-card class="mx-auto my-12" max-width="90%" flat>
      <v-card-title>
        直近のリリース履歴
      </v-card-title>
    </v-card>
    <v-sheet v-for="(item,index) in versions" :key=item.version flat>
      <v-sheet v-if="index < 2" class="mx-auto" max-width="90%" flat>
        <v-timeline dense>
          <v-timeline-item color="light-blue darken-4" fill-dot small>
            <template v-slot:opposite>
            <span
                :class="`headline font-weight-bold`"
                v-text="item.date"
            ></span>
            </template>
            <v-card class="elevation-2">
              <v-card-title :class="`headline font-weight-light`">
                {{item.version}}
              </v-card-title>
              <v-list dense v-for="ticket in item.tickets" :key=ticket.id>
                <v-btn :href="getJiraUrl(ticket.id)"
                       target="_blank"
                       text
                       color="light-blue darken-4">
                  {{ ticket.id }}</v-btn>{{ ticket.title }}
              </v-list>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-sheet>
    </v-sheet>
    <v-row class="mt-5 mb-10">
      <v-btn class="ml-auto" text to="/versions" color="blue-grey">
        more..
      </v-btn>
    </v-row>
  </v-container>
</template>
<style>
:root {
  --timeline-opposite-item-width: 160px;
  --timeline-line-width: 8px;
}

.v-timeline--dense .v-timeline-item__opposite {
  display: inline-block;
}

.v-timeline-item__opposite {
  flex: none;
  min-width: var(--timeline-opposite-item-width);
}

/* line: divider in the middle is 96px wide by default */
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
  left: calc(
      var(--timeline-opposite-item-width) +
      (96px - var(--timeline-line-width)) / 2
  );
  width: var(--timeline-line-width);
}
</style>
<script>
import releaseService from "@/services/releaseService";
import VersionForm from "@/forms/VersionForm";
export default {
  name: "Home",
  data () {
    return {
      versions: VersionForm
    }
  },
  methods: {
    getJiraUrl(ticket_id) {
      return "https://tickets.tools.misumi.jp/jira/browse/" + ticket_id
    }
  },
  async created () {
    const versions = await releaseService.getReleases()
    this.versions = versions
  },
}
</script>